import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './styles/tailwind.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faRightFromBracket,
    faXmark,
    faBars,
    faHouse,
    faWarehouse,
    faBoxOpen,
    faTruckRampBox,
    faTag,
    faBoxArchive,
    faSackDollar,
    faBalanceScale,
    faUsersGear,
    faChevronUp,
    faChevronDown,
    faChevronRight,
    faBook,
    faUpload,
    faCalculator,
    faNewspaper,
    faMoneyBill,
    faChartLine,
    faEye,
    faPencil,
    faTrash,
    faCircleArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import {
    faFacebookSquare,
    faTwitter,
    faLinkedin,
    faInstagram
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import i18n from './i18n'

library.add(
    faRightFromBracket,
    faXmark,
    faBars,
    faHouse,
    faUsersGear,
    faChevronUp,
    faChevronDown,
    faChevronRight,
    faBook,
    faUpload,
    faCalculator,
    faNewspaper,
    faMoneyBill,
    faChartLine,
    faEye,
    faPencil,
    faTrash,
    faCircleArrowRight,
    faFacebookSquare,
    faTwitter,
    faLinkedin,
    faInstagram,
    faWarehouse,
    faBoxOpen,
    faTruckRampBox,
    faTag,
    faBoxArchive,
    faSackDollar,
    faBalanceScale,
)

router.beforeEach((to, from, next) => {
    let {lang} = to.params;

    if (!i18n.global.availableLocales.includes(lang)) {
        i18n.global.locale = 'es'
        next({path: 'es/home'})
    } else {
        i18n.global.locale = lang
        next()
    }
})

createApp(App).use(i18n).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
