<template>
    <li>
        <router-link :to="{name: to}" @click="closeMenu"
            class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white uppercase"
            active-class="text-orange-500 font-medium"
            exact>
            {{title}}
        </router-link>
    </li>
</template>

<script>
export default {
    props: {
        isActive: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        to: {
            type: String,
            required: true
        }
    }, 
    emits: ['close'],
    methods: {
        closeMenu() {
            this.$emit('close', true)
        }
    }
}
</script>
