<template>
    <li>
        <button 
            @click="toggleOpen"
            class="flex items-center justify-between w-full py-2 pl-3 pr-4 rounded hover:text-orange-500 hover:bg-gray-100 lg:bg-transparent lg:dark:bg-transparent lg:hover:bg-transparent lg:border-0 lg:hover:text-orange-500 lg:p-0 lg:w-auto dark:text-white lg:dark:hover:text-orange-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 lg:dark:hover:bg-transparent uppercase"
            :class="{'text-white lg:text-orange-500 lg:dark:text-orange-600 bg-orange-500 dark:bg-orange-600':isActive, 'text-text-black lg:text-black lg:dark:text-white':!isActive}">
            {{ title }}
            <svg class="w-5 h-5 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"></path>
            </svg>
        </button>
        <!-- Dropdown menu -->
        <div
            :class="{'absolute top-14': open, 'hidden':!open}"
            class="z-10 font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-52 dark:bg-gray-700 dark:divide-gray-600">
            <ul class="py-2 text-sm text-gray-700 dark:text-gray-400"
                aria-labelledby="dropdownLargeButton">
                <NavbarDropdownLink :title="$t('nav.services.service-1')" to="guest.services.service1" @click="toggleOpen" />
                <NavbarDropdownLink :title="$t('nav.services.service-2')" to="guest.services.service2" @click="toggleOpen" />
                <NavbarDropdownLink :title="$t('nav.services.service-3')" to="guest.services.service3" @click="toggleOpen" />
                <NavbarDropdownLink :title="$t('nav.services.service-4')" to="guest.services.service4" @click="toggleOpen" />
                <NavbarDropdownLink :title="$t('nav.services.service-5')" to="guest.services.service5" @click="toggleOpen" />
                <NavbarDropdownLink :title="$t('nav.services.service-6')" to="guest.services.service6" @click="toggleOpen" />
                <NavbarDropdownLink :title="$t('nav.services.service-7')" to="guest.services.service7" @click="toggleOpen" />
            </ul>
        </div>
    </li>
</template>

<script>
import NavbarDropdownLink from './NavbarDropdownLink.vue';

export default {
    components: {
        NavbarDropdownLink
    },
    props: {
        title: {
            type: String
        },
        isActive: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            open: false
        }
    },
    methods: {
        toggleOpen() {
            this.open = !this.open
        }
    },
}
</script>