export default {
    name: 'services',
    component: () => import(/* webpackChunkName: "Services-layout" */ '@/modules/Services/layouts/ServicesLayout.vue'),
    children: [
        {
            path: '1',
            name: 'guest.services.service1',
            component: () => import(/* webpackChunkName: "Services-index" */ '@/modules/Services/views/Service1.vue'),
        },
        {
            path: '2',
            name: 'guest.services.service2',
            component: () => import(/* webpackChunkName: "Services-index" */ '@/modules/Services/views/Service2.vue'),
        },
        {
            path: '3',
            name: 'guest.services.service3',
            component: () => import(/* webpackChunkName: "Services-index" */ '@/modules/Services/views/Service3.vue'),
        },
        {
            path: '4',
            name: 'guest.services.service4',
            component: () => import(/* webpackChunkName: "Services-index" */ '@/modules/Services/views/Service4.vue'),
        },
        {
            path: '5',
            name: 'guest.services.service5',
            component: () => import(/* webpackChunkName: "Services-index" */ '@/modules/Services/views/Service5.vue'),
        },
        {
            path: '6',
            name: 'guest.services.service6',
            component: () => import(/* webpackChunkName: "Services-index" */ '@/modules/Services/views/Service6.vue'),
        },
        {
            path: '7',
            name: 'guest.services.service7',
            component: () => import(/* webpackChunkName: "Services-index" */ '@/modules/Services/views/Service7.vue'),
        },
    ]
}