export default {
    name: 'aboutus',
    component: () => import(/* webpackChunkName: "Aboutus-layout" */ '@/modules/Aboutus/layouts/AboutusLayout.vue'),
    children: [
        {
            path: '',
            name: 'guest.aboutus.index',
            component: () => import(/* webpackChunkName: "Aboutus-index" */ '@/modules/Aboutus/views/Index.vue'),
        },
    ]
}