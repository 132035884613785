<template>
        <button @click="toggleOpen" type="button"
            class="inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-gray-900 dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
            <img v-if="$i18n.locale == 'es'" src="@/assets/flags/flag_es.png" class="w-5 h-5 mr-2 rounded-full" />
            <img v-if="$i18n.locale == 'en'" src="@/assets/flags/flag_en.png" class="w-5 h-5 mr-2 rounded-full" />
            <span>{{ $i18n.locale == 'es' ? 'Español (MX)' : 'English (US)' }}</span>
        </button>
        <!-- Dropdown -->
        <div :class="{'absolute top-11': open, 'hidden':!open}" class="z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700">
            <ul class="py-2 font-medium" role="none">
                <li>
                    <button 
                        type="button"
                        @click="setLocal('en')"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                        role="menuitem">
                        <div class="inline-flex items-center">
                            <img src="@/assets/flags/flag_en.png" class="h-3.5 w-3.5 rounded-full mr-2" />
                            English (US)
                        </div>
                    </button>
                </li>
                <li>
                    <button 
                        type="button"
                        @click="setLocal('es')"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                        role="menuitem">
                        <div class="inline-flex items-center">
                            <img src="@/assets/flags/flag_es.png" class="h-3.5 w-3.5 rounded-full mr-2" />
                            Español (MX)
                        </div>
                    </button>
                </li>
            </ul>
        </div>
</template>

<script>
    export default {
        data() {
            return {
                open: false
            }
        },
        methods: {
            setLocal(locale) {
                this.$i18n.locale = locale
                this.$router.push({
                    params: { lang: locale }
                })
                this.open = false
            },
            isEs() {
                console.log(this.$i18n.locale == 'es');
                return this.$i18n.locale == 'es'
            },
            toggleOpen() {
                this.open = !this.open
            }
        }
    }
</script>