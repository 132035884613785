export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "services": {
      "servicesDropdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
      "service-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NATIONAL STORAGE"])},
      "service-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BONDED WAREHOUSE"])},
      "service-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-COMMERCE"])},
      "service-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISTRIBUTION AND TRANSPORTATION"])},
      "service-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LABELING, PACKAGING, PACKAGING AND SUBASSEMBLY"])},
      "service-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUSTOMS PROCEDURES AND LEGAL REQUIREMENTS"])},
      "service-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRADE COMPANY"])}
    },
    "aboutus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "contactus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved."])}
  },
  "homePage": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your ally in storage and distribution: Reliable customized services"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimizes costs, thereby eliminating investments in property, personnel, forklifts, surveillance, etc."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
    },
    "clients": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many satisfied customers support us in Mexico and the USA."])},
      "alliances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main alliances"])}
    }
  },
  "servicesPage": {
    "service-1": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National Storage"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preserve and take care of your merchandise, offering efficient real-time inventory control through our Warehouse Management System"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custody and storage of your merchandise."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory control in real time."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide reorder points."])},
          "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In & Out system."])},
          "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picking orders."])},
          "six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom reporting."])}
        }
      }
    },
    "service-2": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonded Warehouse"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defer the payment of taxes on your imports by partially or fully extracting the goods"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial benefit, on the deferred payment of material import taxes."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtaining tax credits."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates of deposit and pledge bonds."])},
          "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized Verification Unit (UVA) by SECOFI."])},
          "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarantees for credits."])}
        }
      }
    },
    "service-3": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Commerce"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure and protected storage of products; inventory management including real-time tracking, orders, and replenishment"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product storage."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt of orders in system."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparation of the shipment by picking orders."])},
          "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory control and reorder points."])},
          "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alliances with the best carriers for shipments."])}
        }
      }
    },
    "service-4": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution and Transportation"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local and long-distance land services, door-to-door from Mexico to the USA, import and export (LTL and FTL), last-mile deliveries"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrestrial, national and foreign services."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import and Export (LTL and FTL)."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door to door, Mexico to USA or vice versa."])},
          "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last mile deliveries."])}
        }
      }
    },
    "service-5": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labeling"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance with Mexican official standards, authorized verification unit, and labeling contract manufacturing"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrapping and strapping services."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process of subassemblies, repackaging, labeling and packaging."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picking orders."])}
        }
      }
    },
    "service-6": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging, Crating, and Subassembly"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services including wrapping, strapping, picking, subassemblies, repackaging, and conditioning"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal consultancy in import and export."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatch of goods in the main customs of the country."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use of our fiscal deposit."])},
          "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance with the Official Mexican Standard (NOM)."])},
          "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorized Verification Unit (UVA)."])},
          "six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin inspections."])}
        }
      }
    },
    "service-7": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customs Procedures and Legal Requirements"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customs clearance at the country's main customs, legal consultancy for import and export"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed in the register of importers."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing, distribution, purchase or sale, import of a finished product or service outside the country quickly and easily."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed in the processes to increase the value offer for our clients."])},
          "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greater control in the procedures."])},
          "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ability to negotiate and network of contacts."])}
        }
      }
    }
  },
  "subsidiariesPage": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore Our Branches"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the nearest one and access our services with convenience and guaranteed quality"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
    },
    "subsidiaries": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover our incredible branches! We invite you to have a unique experience, where you will find storage, distribution, customs procedures, and more. Come and see everything we have to offer!"])}
    }
  },
  "aboutusPage": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experts in Logistics and Storage"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete solutions for efficient handling of your merchandise and inventory control"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
    },
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With a trajectory of more than 5 years, we are a leading company in comprehensive solutions for storage, inventory control, transportation, and subassembly production. Additionally, we have advanced computer systems that guarantee efficient and precise management of the entire logistics chain. We also offer professional customs services that simplify procedures and expedite international trade. Our commitment is to provide our clients with a complete and personalized solution for the handling of their merchandise, optimizing processes and ensuring a seamless flow from production to final delivery"])},
    "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trust, Respect, Teamwork, and Punctuality are the fundamental values that characterize us and drive us in every aspect of our work"])},
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission: To meet the storage and operational needs of our clients through continuous improvement in our warehouses, offering simple and flexible solutions to their needs"])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vision: To be the best allies of our clients, offering the best locations with state-of-the-art facilities and thus satisfying their needs with the best service"])},
    "promeses": {
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Promise"])},
      "promise-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer the best service: Whether you are a large company or a small business, we will become your best ally and help you solve all your needs"])},
      "promise-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuous Improvement: We are all constantly training to continue consolidating ourselves as the best storage and logistics company and offer our employees the best environment to develop"])},
      "promise-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passion: We are a team that is truly passionate about helping solve any storage and logistics-related needs, and we are dedicated to achieving it"])}
    }
  },
  "contactusPage": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested in Our Services?"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us right away and discover how we can assist you with your logistics and customs needs"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a Message!"])}
    },
    "socialmedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us on social media to stay updated on our logistics services! You can also request personalized advice to optimize your supply chain"])},
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For personalized assistance and sales inquiries, contact us by email or call us directly. We are here to help you close the contract that best suits your needs"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "emailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["javier", "@", "bdglogistica.com"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
      "phoneText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+52 (662) 143 8751"])}
    }
  },
  "servicesSection": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover a Wide Range of Logistics Services to Meet All Your Needs"])}
    }
  },
  "subsidiariesSection": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We invite you to explore our diverse warehouses, designed to meet your storage needs with flexible and secure options"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get to Know Them Now!"])}
    }
  },
  "faqsSection": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do You Have Questions? Don't Worry, We're Here to Help"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore our Frequently Asked Questions section, where you will find detailed answers and practical solutions to your most common concerns"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ's"])}
    }
  },
  "whySection": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why Warehouse Services?"])},
      "response-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce your costs"])},
      "response-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customize solutions to meet your needs and the needs of your customers"])},
      "response-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measure our deliverables and report them to our clients regularly"])},
      "response-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide turnkey solutions - you only pay us a margin"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])}
    }
  }
}