export default {
    name: 'home',
    component: () => import(/* webpackChunkName: "Home-layout" */ '@/modules/Home/layouts/HomeLayout.vue'),
    children: [
        {
            path: '',
            name: 'guest.home.index',
            component: () => import(/* webpackChunkName: "Home-index" */ '@/modules/Home/views/Index.vue'),
        },
    ]
}