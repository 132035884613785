export default {
    name: 'contactus',
    component: () => import(/* webpackChunkName: "Contactus-layout" */ '@/modules/Contactus/layouts/ContactusLayout.vue'),
    children: [
        {
            path: '',
            name: 'guest.contactus.index',
            component: () => import(/* webpackChunkName: "Contactus-index" */ '@/modules/Contactus/views/Index.vue'),
        },
    ]
}