
<template>
    <footer class="bg-white dark:bg-gray-900">
        <MainContainer>
            <div class="pt-12 pb-6 lg:pt-20">
                <div class="lg:flex lg:justify-between">
                    <div class="mt-6 mb-12 lg:mt-0 lg:mb-0 flex justify-center">
                        <router-link :to="{name: 'guest.home.index'}">
                            <slot name="logo" />
                        </router-link>
                    </div>
                    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 ml-20">
                        <div>
                            <h2 class="mb-4 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                                {{$t('nav.services.servicesDropdown')}}
                        </h2>
                            <ul class="text-orange-600 dark:text-orange-400 font-medium">
                                <li class="mb-3">
                                    <router-link :to="{name: 'guest.services.service1'}" 
                                        @click="scrollToTop" 
                                        class="hover:underline uppercase">
                                        {{$t('nav.services.service-1')}}
                                    </router-link>
                                </li>
                                <li class="mb-3">
                                    <router-link :to="{name: 'guest.services.service2'}" 
                                        @click="scrollToTop"
                                        class="hover:underline uppercase">
                                        {{$t('nav.services.service-2')}}
                                    </router-link>
                                </li>
                                <li class="mb-3">
                                    <router-link :to="{name: 'guest.services.service3'}" 
                                        @click="scrollToTop"
                                        class="hover:underline uppercase">
                                        {{$t('nav.services.service-3')}}
                                    </router-link>
                                </li>
                                <li class="mb-3">
                                    <router-link :to="{name: 'guest.services.service4'}" 
                                        @click="scrollToTop"
                                        class="hover:underline uppercase">
                                        {{$t('nav.services.service-4')}}
                                    </router-link>
                                </li>
                                <li class="mb-3">
                                    <router-link :to="{name: 'guest.services.service5'}" 
                                        @click="scrollToTop"
                                        class="hover:underline uppercase">
                                        {{$t('nav.services.service-5')}}
                                    </router-link>
                                </li>
                                <li class="mb-3">
                                    <router-link :to="{name: 'guest.services.service6'}"
                                        @click="scrollToTop"
                                        class="hover:underline uppercase">
                                        {{$t('nav.services.service-6')}}
                                    </router-link>
                                </li>
                                <li class="mb-3">
                                    <router-link :to="{name: 'guest.services.service7'}"
                                        @click="scrollToTop" 
                                        class="hover:underline uppercase">
                                        {{$t('nav.services.service-7')}}
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="grid grid-cols-1">
                            <div class="mb-6 sm:mb-0">
                                <h2 class="mb-4 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                                    {{$t('nav.company')}}
                                </h2>
                                <ul class="text-orange-600 dark:text-orange-400 font-medium">
                                    <li class="mb-3">
                                        <router-link :to="{name: 'guest.aboutus.index'}" 
                                            @click="scrollToTop"
                                            class="hover:underline uppercase ">
                                            {{$t('nav.aboutus')}}
                                        </router-link>
                                    </li>
                                    <li class="mb-3">
                                        <router-link :to="{name: 'guest.contactus.index'}" 
                                            @click="scrollToTop"
                                            class="hover:underline uppercase">
                                            {{$t('nav.contactus')}}
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />

                <div class="sm:flex text-center sm:text-left sm:items-center sm:justify-between">
                    <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© {{ yearNow }} <router-link :to="{name: 'guest.home.index'}" class="hover:underline hover:text-orange-500">BDG Logistica</router-link>. {{$t('nav.copyright')}}
                    </span>
                    <div class="flex mt-4 space-x-6 justify-center sm:mt-0">
                        <a href="https://www.facebook.com/bdglogistica" target="_blank" class="text-gray-500 hover:text-orange-500 dark:hover:text-white">
                            <FontAwesomeIcon icon="fa-brands fa-facebook-square"/>
                            <span class="sr-only">Facebook page</span>
                        </a>
                        <a href="https://www.linkedin.com/company/bdg-logistica" target="_blank" class="text-gray-500 hover:text-orange-500 dark:hover:text-white">
                            <FontAwesomeIcon icon="fa-brands fa-linkedin"/>
                            <span class="sr-only">Linkedin</span>
                        </a>
                    </div>
                </div>
            </div>
        </MainContainer>
    </footer>
</template>

<script setup>
    import MainContainer from '@/shared/components/Containers/MainContainer.vue';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    const scrollToTop = () => window.scrollTo(0,0);
    const yearNow = new Date().getFullYear();
</script>