<template>
    <div class="flex h-screen bg-black justify-center items-center  content-center">
        <div class="bg-white p-20 rounded-lg">
            <h1 class="text-2xl">
                404 | Not found
            </h1>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NotFound'
    }
</script>
