<template>
    <li>
        <router-link :to="{name: to}"
            class="block py-2 pl-3 pr-4 rounded lg:bg-transparent lg:dark:bg-transparent lg:p-0 hover:text-orange-500 dark:dark:text-orange-600 hover:bg-gray-100 lg:hover:bg-transparent lg:dark:text-white uppercase"
            aria-current="page" 
            active-class="text-white lg:text-orange-500 lg:dark:text-orange-600 bg-orange-500 dark:bg-orange-600"
            exact>
            {{title}}
        </router-link>
    </li>
</template>

<script>
    export default {
        props: {
            isActive: {
                type: Boolean,
                default: false
            },
            title: { 
                type: String,
                required: true
            },
            to: {
                type: String,
                required: true
            },
        }
    }
</script>
