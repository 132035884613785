export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "services": {
      "servicesDropdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios"])},
      "service-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALMACENAJE NACIONAL"])},
      "service-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEPOSITO FISCAL"])},
      "service-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-COMMERCE"])},
      "service-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DISTRIBUCION Y TRANSPORTE"])},
      "service-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ETIQUETADO, EMPAQUE, EMBALAJE Y SUBENSAMBLE"])},
      "service-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRAMITES ADUANALES Y REQUISITOS LEGALES"])},
      "service-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMERCIALIZADORA"])}
    },
    "aboutus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosotros"])},
    "contactus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservados todos los derechos."])}
  },
  "homePage": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu aliado en almacenaje y distribución: Servicios confiables a medida"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimiza costos, eliminando con esto inversiones en inmueble, personal, montacargas, vigilancia, etc."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])}
    },
    "clients": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muchos clientes satisfechos nos respaldan en México y USA."])},
      "alliances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principales alianzas"])}
    }
  },
  "servicesPage": {
    "service-1": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenaje Nacional"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preservamos y cuidamos tu mercancía, ofreciendo un control de inventarios eficiente en tiempo real gracias a nuestro eficiente sistema de Warehouse Management System"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custodia y almacenaje de tu mercancía."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control de Inventarios en tiempo real."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporcionar puntos de reorden."])},
          "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In & Out system."])},
          "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picking orders."])},
          "six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporteo a la medida."])}
        }
      }
    },
    "service-2": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposito Fiscal"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferimos el pago de impuestos en tus importaciones extrayendo parcial o totalmente las mercancías"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficio financiero, sobre el pago diferido de los impuestos de importación de material."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtención de créditos fiscales."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificados de deposito y bonos de prenda."])},
          "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidad de Verificación Autorizada (UVA) por SECOFI."])},
          "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantias para créditos."])}
        }
      }
    },
    "service-3": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Commerce"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenamiento seguro y protegido de productos; gestión de inventario que incluye el seguimiento en tiempo real, pedidos y reabastecimiento"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacenaje de los productos."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibo de ordenes en sistema."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparacion del envio por picking orders."])},
          "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control de inventarios y puntos de reorden."])},
          "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alianzas con los mejores carriers para envios."])}
        }
      }
    },
    "service-4": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribución y Transporte"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios terrestres locales y foráneos, puerta a puerta de México a USA, importación y exportación (LTL y FTL), entregas de última milla"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios terrestres, nacionales y foráneos."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importación y Exportación (LTL y FTL)."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door to door, Mexico a USA o viceversa."])},
          "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregas de ultima milla."])}
        }
      }
    },
    "service-5": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetado, Empaque, Embalaje y Subensamble"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios de emplayado, flejado, picking, subensambles, reempaques y acondicionamientos"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios de emplayado y flejado."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso de subensambles, reempaques, etiquetado y acondicionamientos."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picking orders."])}
        }
      }
    },
    "service-6": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trámites Aduanales y Requisitos Legales"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despacho de mercancías en las principales aduanas del país, consultoría legal en importación y exportación"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultoría legal en importación y exportación."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despacho de mercancías en las principales aduanas del país."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprovechamiento de nuestro deposito fiscal."])},
          "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumplimiento de la Norma Oficial Mexicana (NOM)."])},
          "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidad de Verificación Autorizada (UVA)."])},
          "six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspecciones de origen."])}
        }
      }
    },
    "service-7": {
      "hero": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comercializadora"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio que facilita trámites aduanales y agiliza procesos operativos de importación y exportación"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])},
        "bullets": {
          "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscritos en el padrón de importadores."])},
          "two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comercialización, distribución, compra o venta, importación de un producto o servicio terminado fuera del país de manera rápida y sencilla."])},
          "three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapidez en los procesos para aumentar la oferta de valor en nuestros clientes."])},
          "four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayor control en los tramites."])},
          "five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacidad de negociar y redes de contactos."])}
        }
      }
    }
  },
  "subsidiariesPage": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explora nuestras sucursales"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentra la más cercana y accede a nuestros servicios con comodidad y calidad garantizada"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])}
    },
    "subsidiaries": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Descubre nuestras increíbles sucursales! Te invitamos a vivir una experiencia única, donde encontrarás servicios de almacenaje, distribución, trámites aduanales y más. ¡Ven y conoce todo lo que tenemos para ofrecerte!"])}
    }
  },
  "aboutusPage": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertos en logística y almacenamiento"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluciones completas para el manejo eficiente de tus mercancías y control de inventarios"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])}
    },
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con una trayectoria de más de 5 años, somos una empresa líder en soluciones integrales de almacenaje, control de inventarios, transporte y producción de subensambles. Además, contamos con sistemas informáticos avanzados que garantizan una gestión eficiente y precisa de toda la cadena logística. Asimismo, ofrecemos servicios aduanales profesionales que simplifican los trámites y agilizan el comercio internacional. Nuestro compromiso es brindar a nuestros clientes una solución completa y personalizada para el manejo de sus mercancías, optimizando los procesos y asegurando un flujo sin problemas desde la producción hasta la entrega final"])},
    "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Confianza, el Respeto, el Trabajo en Equipo y la Puntualidad son los valores fundamentales que nos caracterizan y nos impulsan en cada aspecto de nuestro trabajo"])},
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misión: Satisfacer las necesidades de almacenaje y operaciones de nuestros clientes mediante una mejora continua en nuestras bodegas, ofreciendo soluciones simples y flexibles a sus necesidades"])},
    "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visión: Ser los mejores aliados de nuestros clientes, ofreciendo las mejores ubicaciones con instalaciones de vanguardia y así, satisfacer sus necesidades con el mejor servicio"])},
    "promeses": {
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestra promesa"])},
      "promise-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofrecer la mejor atención: Seas una gran empresa o un negocio pequeño, nos convertiremos en tu mejor aliado y te ayudaremos a resolver todas tus necesidades"])},
      "promise-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mejora Continua: Todos estamos en constante capacitación para seguirnos consolidando como la mejor empresa de almacenaje y logística, y ofrecerle a nuestros colaboradores el mejor entorno para desarrollarse"])},
      "promise-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasión: Somos un equipo al que realmente nos apasiona ayudar a solucionar cualquier necesidad relacionada con almacenaje y logística, y nos entregamos para lograrlo"])}
    }
  },
  "contactusPage": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Interesado en nuestros servicios?"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctanos ahora mismo y descubre cómo podemos ayudarte con tus necesidades logísticas y aduanales"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Escribe un mensaje!"])}
    },
    "socialmedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Síguenos en redes sociales para estar al tanto de nuestros servicios logísticos! También puedes solicitar asesoría personalizada para optimizar tu cadena de suministro"])},
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para obtener asistencia personalizada y realizar consultas de ventas, contáctanos por correo o llámanos directamente. Estamos aquí para ayudarte a cerrar el contrato que mejor se adapte a tus necesidades"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "emailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["javier", "@", "bdglogistica.com"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
      "phoneText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+52 (662) 143 8751"])}
    }
  },
  "servicesSection": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubre una amplia gama de servicios logísticos para satisfacer todas tus necesidades"])}
    }
  },
  "subsidiariesSection": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te invitamos a explorar nuestras variadas bodegas, diseñadas para satisfacer tus necesidades de almacenamiento con opciones flexibles y seguras"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Conocelas ahora!"])}
    }
  },
  "faqsSection": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tienes preguntas? No te preocupes, estamos aquí para ayudarte"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explora nuestra sección de Preguntas Frecuentes, donde encontrarás respuestas detalladas y soluciones prácticas a tus inquietudes más comunes"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ'S"])}
    }
  },
  "whySection": {
    "hero": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Por qué servicios de almacén?"])},
      "response-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduzca sus costos"])},
      "response-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalice las soluciones para satisfacer sus necesidades y las necesidades de sus clientes"])},
      "response-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medir nuestros entregables y reportarlos a nuestros clientes de manera regular"])},
      "response-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brindamos soluciones llave en mano - solo usted páganos un margen"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactanos"])}
    }
  }
}