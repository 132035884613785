import { createRouter, createWebHistory } from 'vue-router'
import i18n                 from '@/i18n'
import GuestLayout          from '@/shared/layouts/GuestLayout.vue'
import HomeRouter           from '@/modules/Home/router'
import AboutusRouter        from '@/modules/Aboutus/router'
import ContactusRouter      from '@/modules/Contactus/router'
import SubsidiariesRouter   from '@/modules/Subsidiaries/router'
import ServicesRouter       from '@/modules/Services/router'
import NotFound             from '@/shared/views/NotFound.vue'

const routes = [
  {
    path: '/',
    redirect: `/${i18n.global.locale}/home`,
  },
  {
    path: '/:lang',
    name: 'guest',
    component: GuestLayout,
    children: [
      {
        path: '/:lang/home',
        ...HomeRouter
      },
      {
        path: '/:lang/aboutus',
        ...AboutusRouter
      },
      {
        path: '/:lang/contactus',
        ...ContactusRouter
      },
      {
        path: '/:lang/subsidiaries',
        ...SubsidiariesRouter
      },
      {
        path: '/:lang/services',
        ...ServicesRouter
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
